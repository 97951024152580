import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.scss'
})
export class SnackbarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    //teste de notificação
  //   if (Notification && Notification.permission != 'denied') {
  //     Notification.requestPermission(() => {
  //       let message = new Notification(
  //         this.data.title,
  //         {
  //           body: this.data.content,
  //           icon: '../../favicon.ico'
  //         })
  //     })
  //   }
  }

}