import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarComponent } from "../component/other/snackbar/snackbar.component";
import { ApiErrorPipe } from "../shared/pipes/api-error.pipe";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  message: string;

  constructor(
    private snackbar: MatSnackBar,
    /** 
     * CIRCULAR DEPENDENCY HERE  
     * 
     * @TODO remove the TranslateService from here, pass the texts already translated
     * 
     */
    private translateService: TranslateService 
  ) { }

  show(name = this.message, success = true, param?: any, content = true, transale = true) {
    if (!name) {
      return;
    }
    const subtitle = content ? 'CONTENT' : 'NOT_INTEGRATED';
    let data;
    if(transale){
      data = {
        title: this.translateService.instant(`MESSAGE.${name}.TITLE`, param),
        content: this.translateService.instant(`MESSAGE.${name}.${subtitle}`, param),
        success: success
      }
    }
    else{
      data = {
          title: `Erro`, 
          param,
          content: `${name}`,
          success: false
      }
    }
    this.snackbar.openFromComponent(
      SnackbarComponent,
      {
        data: data,
        duration: 5000,
        horizontalPosition: 'right',
        panelClass: success ? 'snackbar-success' : 'snackbar-error',
        verticalPosition: 'top'
      }
    );
    
    this.message = '';
  }

  success(message: string, param?: any) {
    this.snackbar.openFromComponent(
      SnackbarComponent,
      {
        data: {
          title: this.translateService.instant(`MESSAGE.${message}.TITLE`, param),
          // title: message, // title
          content: this.translateService.instant(`MESSAGE.${message}.CONTENT`, param),
          // content: message, // content
          success: true
        },
        duration: 5000,
        horizontalPosition: 'right',
        panelClass: 'snackbar-success',
        verticalPosition: 'top'
      }
    );
  }

  error(message: string, param?: any, error?: any) {
    let errorDescription;
    if(error){
      const errorKey = 'ELEMENT.ERROR.' + new ApiErrorPipe().transform(error)
      errorDescription = this.translateService.instant(errorKey);
    }
    this.snackbar.openFromComponent(
      SnackbarComponent,
      {
        data: {
          title: this.translateService.instant(`MESSAGE.${message}.TITLE`, param),
          // title: message, // title
          content: this.translateService.instant(`MESSAGE.${message}.CONTENT`, param),
          // content: message, // content
          success: false,
          error: errorDescription
        },
        duration: 5000,
        horizontalPosition: 'right',
        panelClass: 'snackbar-error',
        verticalPosition: 'top'
      }
    );
  }

  info(message: string, param?: any, duration = 5000) {
    this.snackbar.openFromComponent(
      SnackbarComponent,
      {
        data: {
          title: this.translateService.instant(`MESSAGE.${message.toUpperCase()}.TITLE`, param),
          // title: message,// title
          content: this.translateService.instant(`MESSAGE.${message.toUpperCase()}.CONTENT`, param),
          // content: message // content 
        },
        duration: duration,
        horizontalPosition: 'right',
        panelClass: 'snackbar-info',
        verticalPosition: 'top'
      }
    );
  }

}
