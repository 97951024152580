<div class="snackbar">
    <img *ngIf="data.success"
         src="/assets/images/robot/robot_happy_head_notification.svg" />
    <img *ngIf="data.success === false"
         src="/assets/images/robot/robot_sad_head_notification.svg" />
    <div class="text">
      <div class="title"
           [innerHTML]="data.title"></div>
      <div class="content"
           [innerHTML]="data.content"></div>
      <small class="content"
             *ngIf="data.error">
             ( {{ data.error }} )</small>
    </div>
    <mat-icon (click)="snackbar.dismiss()">close</mat-icon>
  </div>